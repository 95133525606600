<template>
  <UserGroupFormV2 :editing-existing="false"/>

</template>

<script>
import UserGroupFormV2 from "@/views/SuperUserViews/UserGroups/UserGroupForm.vue";
export default {
  name: "UserGroupCreate",
  components: {UserGroupFormV2},
  setup() {

  }
}
</script>

<style scoped>

</style>